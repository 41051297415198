body {
  font-family: 'Lato' !important;
  background-color: #fff;
  margin: 0px;
  padding: 0px;
}

.App, .sui-layout {
  background-color: #fff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** Header Section **/
button.sui-layout-sidebar-toggle {
  border:1px solid #00C674;
  color: #00C674;
}

.main-container .sui-layout-header {
  padding: 0px;
  border: none;
}
.outer-header {
  width: 100%;
  background: radial-gradient(circle, rgba(52,46,155,0.1) 0%, #261E81 200%), url(../src/assets/architecture-buildings-city.png);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 101px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 6px;
  margin-bottom: 24px;
}
.outer-header .inner-content {
  width: 100%;
  max-width: 998px;
  margin: 0 auto;
  padding: 10px 27px;
}
.outer-header h1 {
  margin: 0px;
  padding: 0px;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  max-width: 350px;
  width: 100%;
}

/** Header Section - END**/

/** Body Container Section **/

.main-container .sui-layout-body__inner {
  width: 100%;
  max-width: 998px;
  padding: 0 15px;
  margin: 25px auto 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-family: 'Lato' !important;
}

    /** Sidebar Section **/

    .main-container .sui-layout-sidebar {
      width: 224px;
      margin-right: 50px;
      padding: 0px;
      border-radius: 6px;
      background-color: #FFFFFF;
      box-shadow: 0 4px 17px 3px rgba(0, 0, 0, 0.11);
      box-sizing: border-box;

    }
    .main-container .sui-layout-sidebar select {
      background-color: #fff!important;
    }
      .main-container .sui-layout-sidebar.sui-layout-sidebar--toggled {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
        margin:0px;
      }

      .main-container .sui-layout-sidebar .sui-facet {
        padding-left: 19px;
        padding-right: 19px;
        padding-bottom: 19px;
        border-bottom: 1px solid #f2f3f3;
        margin-top: 19px;
      }

      .main-container .sui-layout-sidebar .sui-multi-checkbox-facet {
        max-height: 98px;
        overflow-y: scroll;
        overflow-x: hidden;
      }
      .main-container .sui-layout-sidebar .sui-multi-checkbox-facet__option-count {
        margin-right: 5px;
      }
      .main-container .sui-multi-checkbox-facet__option-count {
        margin-left: 10px!important;
      }

      .main-container .sui-layout-sidebar .sui-facet span.sui-multi-checkbox-facet__input-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        width: 115px;
        line-height: 14px;
        color: #83919F;
        font-size: 12px;
        font-weight: bold;
      }
      
      .main-container .sui-layout-sidebar .sui-facet__title {
        color: #607C98;
        font-size: 14px;
        font-weight: bold;
        text-transform: capitalize;
      }
      
      .main-container .sui-facet {
        font-family: 'Lato' !important;
      }

      .sui-facet-view-more {
        color: #0CAB7C;
        font-size: 9px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 11px;
        border: none;
        outline: none;
        margin-top: 8px;
        margin-left: 5px;
      }

      .sui-facet-view-more:hover, .sui-facet-view-more:focus {
        background-color: transparent;
        outline: none;
      }

      .sui-facet-search__text-input:focus {
        border: 1px solid #cccccc;
      }
      
      .sui-facet-search__text-input {
        color: #8AA2AA;
        font-family: Lato;
        font-size: 13px;
      }
      .sui-facet-search ::-webkit-input-placeholder {color:#8AA2AA;}
      .sui-facet-search ::-moz-placeholder {color:#8AA2AA;}
      .sui-facet-search :-ms-input-placeholder {color:#8AA2AA;}
      .sui-facet-search :-moz-placeholder {color:#8AA2AA;}

      .sui-layout-sidebar-toggle {
        margin-top: 0px;
      }

      .sui-layout-body {
        background-color:#fff;
      }

      .sui-layout-sidebar.sui-layout-sidebar--toggled {
        padding-top: 20px !important;
        border-radius: 0 !important;
      }

    /** Sidebar Section - END **/


    /** Body Container **/

    .main-container .sui-layout-main {
      width: 714px;
      padding: 0px;
    }



    .bodyContainer .sui-search-box.custom-search .button.sui-search-box__submit {
      display: none;
    }

    .bodyContainer .sui-search-box__text-input {
      padding: 7px 10px;
      padding-left: 35px;
    }

    .paging-sorting-outer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .paging-sorting-outer .sui-select__control {
      border-radius: 4px;
      background-color: #fff;
      border:1px solid #ccc;
    }
   .sui-select__control--is-focused {
      border: 1px solid #ccc !important
    }

    .paging-sorting-outer .sui-select {
      width: 140px;
      font-size: 11px;
    }

    .paging-sorting-outer .custom-sorting {
      width: 210px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .paging-sorting-outer .sui-sorting__label {
      position: relative;
      top: 5px;
      margin-right: 10px;
    }

    .paging-sorting-outer .sui-select__control {
      height: 30px;
    }

    /** Body Container -- END **/

    .sui-layout-body:after {
      height: 0px;
    }

    .sui-search-box__text-input:focus {
      border:1px solid #ccc;
      box-shadow: none;
    }
    .searchBox-outer {
      position: relative;
    }
    .searchBox-outer .sui-search-box__wrapper:before {
      content: '\e090';
      font-family: 'simple-line-icons';
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      color: #83919F;
      font-size: 14px;
      position: absolute;
      z-index: 1;
      left: 13px;
      height: 35px;
      line-height: 35px;
    }

    .sui-search-box__autocomplete-container.custom-search {
      margin:0px !important;
      padding:10px 0 !important;
    }

    .main-container .sui-layout-sidebar .sui-multi-checkbox-facet__option-input-wrapper {
      display: flex;
      align-items: center;
    }

/** Body Container Section - END **/

/** Media Query Layout **/

@media only screen and (max-width:767px) {
  .outer-header h1 {
      font-size: 16px;
      line-height: 24px;
  }
  .outer-header {
      min-height: 60px;
  }
  .listing-box-heading {
    align-items: center;
  }
  .listing-box-more {
    margin:0px auto 0 24px !important
  }
  .listing-box-tags {
    padding: 7px 0;
  }
  .listing-box-tags .tags-list {
    margin: 0 24px;
  }
}

@media only screen and (max-width:800px) {
  .main-container .sui-layout-body__inner {
    flex-direction: column;
  }
  .main-container .sui-layout-main {
    margin-top: 20px;
    width: 100% !important;
  }
  /* .main-container .sui-layout-sidebar .sui-facet span.sui-multi-checkbox-facet__input-text {
    width: 90% !important;
  } */
  .sui-layout-sidebar {
    z-index: 9999;
  }
}

@media only screen and (min-width:800px) and (max-width:1000px) {
  .main-container .sui-layout-sidebar .sui-facet span.sui-multi-checkbox-facet__input-text {
    width: 73px;
  }  
}

/** Media Query Layout -- END **/


/** Page Scrollbar Layout **/

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

::-webkit-scrollbar-thumb {
  border: 0px solid transparent;
  box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.37);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:active {
  box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.54);
  border-radius: 5px;
}

* {
  scrollbar-width: thin;
}

/** Page Scrollbar Layout -- END **/

html, body {
    overflow: hidden;
 } 

.layout {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.layout .left-side {
  position:absolute;
  top:0;
  left:0;
  width:240px;
  height:100%;
  display: flex;
}

.App {
  height:100%;
  overflow-x: hidden;
  overflow-y:scroll;
  width:100%;
  display: flex;
  position: absolute;
  left:0px;
}


.layout .App.search-filter-mob {
    left:0px !important;
    width: 100% !important;
}

.MuiListSubheader-sticky {
  position: inherit !important;
}

.selected_moreOption span.icon-folder-alt, .selected_moreOption a {
  color: #6e8aa1;
  text-decoration: none;
}


.HeadingOuter {
  display: flex;
  justify-content: space-between;
}

.HeadingOuter .sui-facet__icon {
  color: #607C98;
  font-size: 14px;
}

.main-container .sui-layout-sidebar .sui-facet {
  position: relative;
}

.dropdown-box {
  padding: 10px 15px;
  border: 1px solid #ccc;
  width: 180px;
  position: absolute;
  left: 100%;
  top: -13px;
  z-index: 2;
  background: #fff;
}
.dropdown-box h1 {
  font-size: 16px;
  margin: 0px 0 5px 0;
  padding: 0px;
}
.dropdown-box ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.dropdown-box ul li a {
  color: #000;
  text-decoration: none;
  font-size: 14px;
}
/* .dropdown-box ul li a:hover {
  color: #169781;
} */
.dropdown-box ul li .clicked {
  color: #169781;
}
.dropdown-box::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: -12px;
  top: 10px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent; 
  border-right:12px solid #ccc;
  z-index: 0;
}
.dropdown-box::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: -11px;
  top: 11px;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent; 
  border-right:11px solid #fff;
  z-index: 1;
}

.swagger-ui {
  overflow-y: auto;
  height: 100vh;
}
@media only screen and (max-width:800px) {
  .dropdown-box {
    right: 0px;
    top: 35px;
    left: inherit;
  }
  .dropdown-box::before {
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid #ccc;
    right: 13px;
    top:-24px;
    left: inherit;
  }
  .dropdown-box::after {
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid #FFFFFF;
    right: 13px;
    top:-22px;
    left: inherit;
  }
}