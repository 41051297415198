*  Main Section *
    .disabled {
        cursor: not-allowed;
        pointer-events: none;
    }
    .pointer {
        cursor: pointer;
    }
    .search_clear_outer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 10px;
        flex-wrap: wrap;
    }

    .search_clear_outer div {
        color: #1a2838;
        background: #c1d1db;
        border-radius: 3px;
        margin: 2px 3px;
        padding: 3px 8px;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .search_clear_outer div span span {
        margin-left: 10px;
    }

    .main_searchclear_box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .main_searchclear_box div {
        margin-right: 5px !important;
    }

    .search_clear_outer div div {
        margin:0px;
        padding: 0px;
    }

    .main_searchclear_box strong {
        margin-right: 5px;
    }

    .topHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }

    .leftHeader_item {
        color: #6e8aa1;
        font-family: Lato;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 16px;
        display: flex;
        justify-content: flex-start;
    }
    .leftHeader_item .selected_item {
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid #6e8aa1;
    }
    .leftHeader_item .selected_item span.icon-minus {
        margin-right: 10px;
    }    

    .rightHeader_item {
        display: flex;
        justify-content: flex-start;
        color: #6e8aa1;
        font-size: 13px;
        font-weight: 700;
    }

    .rightHeader_item span.icon-folder-alt {
        margin-right: 10px;
    }

    @media only screen and (max-width:800px) {
        .sui-layout-main-body .sui-results-container {
            margin-left: 35px;
        }
    }
    @media only screen and (max-width:413px) {
        .topHeader {
            flex-direction: column;
        }
        .topHeader .leftHeader_item {
            margin-bottom: 10px;
        }
    }