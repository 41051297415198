/**  Card Section **/
    
.listing-box {
  padding: 0 0 13px 0;
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 17px 3px rgba(0,0,0,0.11);
  box-sizing: border-box;
  margin-bottom: 15px;
}
.listing-box-title p {
  margin:0px;
}
.listing-box:hover {
  box-shadow: 0 4px 17px 3px rgba(0,0,0,0.20);
}

.listing-box-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px 24px 7px;
}
.listing-box-header-description {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 14px 10px 24px;
  font-size: 13px;
}

.listing-box-header .listing-box-logo {
  height: 60px;
  width: 60px;
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 0 9px 2px rgba(41,63,55,0.19);
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listing-box-header .listing-box-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%; 
}

.listing-box-title .author {
  color: #149A82;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 13px;
  margin-bottom: 3px;
}

.listing-box-title .author a {
  color: #149A82;
  text-decoration: none;
}
.listing-box-title .article {
  color: #149A82;
  /* font-size: 15px; */
  letter-spacing: 0;
  line-height: 13px;
  margin-bottom: 3px;
}

.listing-box-title .article a {
  color: #149A82;
  text-decoration: none;
}

.author .fa-link {
  color: #149A82;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 10px;
  margin-right: 5px;
  transform: scaleX(-1);
}

.listing-box-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.cursor {
  cursor: pointer;
}
.listing-box-heading .listing-box-more {
  margin-left: 24px;
  cursor: pointer;
}

.listing-box-more a {
  text-decoration: none;
  display: block;
}

.listing-box-more span {
  color: #169781;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 25px;
}
.listing-box-more .grad-out {
  color: #000!important;
}

.listing-box-heading h2{
  color: #1A2838;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 0.1px;
  line-height: 20px;
  margin:0px;
  width: calc(100% - 50px);
  word-break: break-word;
}

.listing-box-tags {
  background-color: #F6F9FA;
  padding: 8px 24px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap
}

.tags-list span.tag-list-icon {
  color: #99aeb5;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  margin-right: 9px;
  position: relative;
  top: -2px;
}
.tags-listing {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.tags-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.tags-listing-item {
  border: 1px solid #C1D1DB;
  border-radius: 3px;
  background-color: #f6f9fa;
  padding: 2.5px 7px;
  text-align: center;
  color: #8AA2AA;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  margin-right: 3px;
  max-width: 70px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  margin-bottom: 3px;
}

.tags-listing-item.count-item {
  margin-right: 0px;
  padding: 2.5px 3.5px;
  cursor: pointer;
}
.listing-box-tags .divider-tags {
  position: relative;
  top: -4px;
}

.divider-tags {
  margin: 0 13px;
  border-right: 1px solid #C5D3D7;
  width: 1px;
  height: 17px;
}

.listing-footer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 14px 24px 5px;
  align-items: center;
}

.listing-footer .location {
  color: #6D828C;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 15px;
}

.listing-footer .publish {
  color: #6D828C;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 15px;
}

.listing-footer .divider-tags {
  margin: 0 7px;
  border-right: 1px solid #C5D3D7;
  width: 1px;
  height: 12px;
}
.footer-last {
  padding: 2px 24px 5px!important
}

.author-outer div {
  display: inline-block;
  margin-right: 10px;
}

.author-outer a.disabled, .author-outer a.disabled .fa-link {
  color: #607C98;
}

@media only screen and (max-width:767px) {       
  .tags-list span.tag-list-icon {
    width: 15px;
  } 
  .tags-list span.tag-list-icon {
    top: -1px;
  }
  .listing-box-tags {
      flex-direction: column;
      align-items: flex-start;
      padding: 8px 0;
  }
  .listing-box-tags .divider-tags {
      margin: 10px 0;
      background: #C5D3D7;
      width: 100%;
      height: 1px;
      top: 0px;
  }
  .tags-listing-item {
      margin: 3px;
  }
}

@media only screen and (max-width:767px) and (orientation: Portrait) {
  .paging-sorting-outer {
    flex-direction: column;
  }
  .paging-sorting-outer .sui-paging-info {
    margin-top: 10px;
  }
}
.disabled {
pointer-events: none;
cursor: default;
}
.outerCard {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;
}
.outerCard:last-child {
  padding-bottom: 50px;
}
.outerCard .selectionRadio {    
  color: #C2CDDC;
  position: absolute;
  left: -30px;
  top: 35%;
}
.outerCard .selectionRadio span { 
  font-size: 30px;
  position: relative;
  right: 10px;
}

.outerCard .selectionRadio span.selectedItem {
  right: 40px;
}

.main-container .sui-layout-sidebar select {
  border: 1px solid #ccc;
  color: #8AA2AA;
  text-transform: capitalize;
  border-radius: 3px;
  padding: 2px 3px;
  float: right;
  margin-bottom: 8px;
  margin-top: 5px;
}

.outerCard .listing-box {
  width: 100%;
}
.fa-check-circle.selectedItem {
  color: #4B51DD;
  position: absolute !important;
  right: 10px;
}
/** Card Section -- END **/

@media only screen and (min-width:1025px) {
  .outerCard .selectionRadioBefore {opacity: 0;}
  /* .outerCard:hover .selectionRadio {opacity: 1;} */
  .outerCard:hover .hoverClass {
    opacity: 1;
  }
}
/** Card Section -- END **/
